
// Brand Colors
$color-brand-01:                  #0168fa;
$color-brand-02:                  #042893;
$color-brand-03:                  #1ce1ac;

// Inverse Colors
$color-inverse-01:                #001737;
$color-inverse-02:                #1b2e4b;;

// Text Colors
$color-text-01:                   $color-inverse-01;
$color-text-02:                   $color-inverse-02;
$color-text-03:                   #8392a5;
$color-text-04:                   #c0ccda;

// UI Colors
$color-ui-01:                     #f5f6fa;
$color-ui-02:                     #e5e9f2;
$color-ui-03:                     #c0ccda;
$color-ui-04:                     #8392a5;

// Height
$height-base:                     38px;
$height-header:                   60px;
$height-header-mobile:            55px;
$height-footer:                   46px;

// Width
$width-sidebar:                   240px;

// Marker
$marker-icon-size:                16px;

// Fonts
$font-family-roboto:              "Roboto", sans-serif;
$font-family-interui:             "Inter UI", sans-serif;
$font-family-lato:                "Lato", sans-serif;
$font-family-rubik:               "Rubik", sans-serif;
$font-family-system:              -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;

$font-weight-medium:              500;
$font-weight-semibold:            600;
$font-weight-bold:                700;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// $component-active-bg:         theme-color("primary") !default;
$component-active-bg:         $color-brand-01 !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$border-width:                1px !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;

$label-margin-bottom:                   .5rem !default;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-border-width:                    $input-btn-border-width !default;
$input-height-border:                   $input-border-width * 2 !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
